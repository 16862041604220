import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UserInformationService} from '../../services/user-information.service';
import {Subscription} from "rxjs";

@Component({
    selector: 'vi-lite-account',
    templateUrl: './lite-account.component.html',
    styleUrls: ['./lite-account.component.scss']
})
export class LiteAccountComponent implements OnInit {

    isStarted = false;
    private startSubscription: Subscription;
    partnerContactUrl: string;

    constructor(public userInformationService: UserInformationService) {
        this.startSubscription = this.userInformationService.appReadyChanged$.subscribe((ready) => {
            if (!this.isStarted) {
                this.setLink();
                this.isStarted = true;
            }
        });
    }

    ngOnInit() {
        this.userInformationService.initCurrentUser();
    }

    async setLink() {
        if (this.userInformationService.country === 'DE') {
            this.partnerContactUrl = environment.becomePartnerUrlDE;
        } else if (this.userInformationService.country === 'FR') {
            this.partnerContactUrl = environment.becomePartnerUrlFR;
        } else if (this.userInformationService.country === 'BE') {
            this.partnerContactUrl = environment.becomePartnerUrlBE;
        } else if (this.userInformationService.country === 'LU') {
            if (this.userInformationService.language === 'de') {
                this.partnerContactUrl = environment.becomePartnerUrlLUde;
            } else if (this.userInformationService.language === 'fr') {
                this.partnerContactUrl = environment.becomePartnerUrlLUfr;
            }
        } else if (this.userInformationService.country === 'NL') {
            this.partnerContactUrl = environment.becomePartnerUrlNL;
        } else if (this.userInformationService.country === 'IT') {
            this.partnerContactUrl = environment.becomePartnerUrlIT;
        } else if (this.userInformationService.country === 'SK') {
            this.partnerContactUrl = environment.becomePartnerUrlSK;
        } else if (this.userInformationService.country === 'CZ') {
            this.partnerContactUrl = environment.becomePartnerUrlCZ;
        } else if (this.userInformationService.country === 'DK') {
            this.partnerContactUrl = environment.becomePartnerUrlDK;
        } else if (this.userInformationService.country === 'AT') {
            this.partnerContactUrl = environment.becomePartnerUrlAT;
        } else if (this.userInformationService.country === 'SI') {
            this.partnerContactUrl = environment.becomePartnerUrlSI;
        }
    }
}
