import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {SystemDetailService} from '../../services/system-detail.service';
import {SystemDetail} from '../../models/system-detail.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {IncompleteInformationComponent} from './incomplete-information/incomplete-information.component';
import {CertificateComponent} from './certificate/certificate.component';
import {UserInformationService} from '../../services/user-information.service';
import {environment} from '../../../environments/environment';
import {NotRegistratedPopupComponent} from '../systems-overview/not-registrated-popup/not-registrated-popup.component';
import {NotFoundPopupComponent} from '../systems-overview/not-found-popup/not-found-popup.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'vi-system-detail',
    templateUrl: './system-detail.component.html',
    styleUrls: ['./system-detail.component.scss']
})
export class SystemDetailComponent implements OnInit, OnDestroy {
    systemDetail: SystemDetail;
    systemLoaded: Promise<boolean>;
    equipmentNumber: number;
    private subscription: Subscription;
    showSpinner: boolean = true;
    ExistsAndAllowed: number;
    isSEqui: Promise<boolean>;
    systemExists: Promise<boolean>;
    systemAllowed: Promise<boolean>;
    systemNotFullyRegistered: Promise<boolean>;
    viBooksUrl = environment.viBooks;
    vitoGuideUrl = environment.viGuide;
    partnerPortalUrl = environment.partnerPortalUrl;
    partnerPortalControlUrlDE = environment.partnerPortalControlUrlDE;
    heatingSystemRegistrationEquiUrl = environment.heatingSystemRegistrationEqui;
    fioriEquipmentUrl = environment.fioriEquipmentUrl;
    validateEmail = '[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-zA-Z]{2,6}';
    serviceConfiguratorUrl = environment.serviceConfiguratorUrl;
    params: any;
    sEquiSerialNumber: string;

    isLoading = true;
    isStarted = false;
    private startSubscription: Subscription;

    constructor(private systemDetailService: SystemDetailService, private activatedRoute: ActivatedRoute,
                private router: Router, public dialog: MatDialog, public userInformationService: UserInformationService, private _snackBar: MatSnackBar) {
        this.startSubscription = this.userInformationService.appReadyChanged$.subscribe((ready) => {
            if (!this.isStarted) {
                this.handleAppStart();
                this.isStarted = true;
            }
        });
        this.activatedRoute.queryParams.subscribe( params => {
            this.params = params;
        });

    }

    async handleAppStart() {

        this.startComponent();
        this.isLoading = false;
    }

    ngOnInit() {
        this.userInformationService.initCurrentUser();
    }

    startComponent() {
        if (this.params.customer && (this.userInformationService.isVi())) {
            this.userInformationService.shownCustomer = this.params.customer;
        }
        this.subscription = this.activatedRoute.params.subscribe(
            async params => {
                this.equipmentNumber = +params['equinumber'];
                this.systemDetailService.getById(this.equipmentNumber, this.userInformationService.shownCustomer)
                    .subscribe(data => {
                        this.systemDetail = data;
                        // Equi does not exist = 0
                        if (this.systemDetail.securityStatus === 0) {
                            this.showSpinner = false;
                            this.isSEqui = Promise.resolve(false);
                            this.systemExists = Promise.resolve(true);
                            this.systemAllowed = Promise.resolve(false);
                            this.systemLoaded = Promise.resolve(false);
                            this.systemNotFullyRegistered = Promise.resolve(false);
                        }
                        // Equi is not allowed to be viewed = 1
                        else if (this.systemDetail.securityStatus === 1) {
                            this.showSpinner = false;
                            this.isSEqui = Promise.resolve(false);
                            this.systemExists = Promise.resolve(false);
                            this.systemAllowed = Promise.resolve(true);
                            this.systemLoaded = Promise.resolve(false);
                            this.systemNotFullyRegistered = Promise.resolve(false);
                        }
                        // Equi exists and is allowed = 2
                        else if (this.systemDetail.securityStatus === 2) {
                            this.userInformationService.shownEquipment = this.equipmentNumber.toString();
                            this.showSpinner = false;
                            this.isSEqui = Promise.resolve(false);
                            this.systemExists = Promise.resolve(false);
                            this.systemAllowed = Promise.resolve(false);
                            this.systemLoaded = Promise.resolve(true);
                            this.systemNotFullyRegistered = Promise.resolve(false);
                            this.fioriEquipmentUrl = this.fioriEquipmentUrl.replace(/\{equipment\}/g, this.systemDetail.equipment.equipmentNumber);
                            this.parametrizeUrls();
                            this.systemDetail.vitoGuideId = this.systemDetail?.structure.find(s => s.vitoguideId).vitoguideId;
                        }
                        // Equi is S-Equi which are not allowed
                        else if (this.systemDetail.securityStatus === 3) {
                            this.sEquiSerialNumber = this.getSEquiSerialNumber();
                            this.showSpinner = false;
                            this.isSEqui = Promise.resolve(true);
                            this.systemExists = Promise.resolve(false);
                            this.systemAllowed = Promise.resolve(false);
                            this.systemLoaded = Promise.resolve(false);
                            this.systemNotFullyRegistered = Promise.resolve(false);
                        }
                        // Equi is not fully registered
                        else if (this.systemDetail.securityStatus === 4) {
                            this.showSpinner = false;
                            this.isSEqui = Promise.resolve(false);
                            this.systemExists = Promise.resolve(false);
                            this.systemAllowed = Promise.resolve(false);
                            this.systemLoaded = Promise.resolve(false);
                            this.systemNotFullyRegistered = Promise.resolve(true);
                        }
                    }, error => {
                        this.showSpinner = false;
                        this.isSEqui = Promise.resolve(false);
                        this.systemExists = Promise.resolve(true);
                        this.systemAllowed = Promise.resolve(false);
                        this.systemLoaded = Promise.resolve(false);
                        this.systemNotFullyRegistered = Promise.resolve(false);
                    });
            }
        );
    }

    parametrizeUrls() {
        this.viBooksUrl = this.viBooksUrl.replace('{languageCode}', this.userInformationService.language)
            .replace('{countryCode}', this.userInformationService.country).replace('{systemId}', this.systemDetail?.equipment.equipmentNumber);
        this.vitoGuideUrl = this.vitoGuideUrl.replace('{installationId}', this.systemDetail?.vitoGuideId);
        this.serviceConfiguratorUrl = this.serviceConfiguratorUrl.replace('{equipmentId}', this.systemDetail?.equipment.equipmentNumber);
        this.heatingSystemRegistrationEquiUrl = this.heatingSystemRegistrationEquiUrl.replace('{systemId}', this.systemDetail?.equipment.equipmentNumber);
        this.partnerPortalUrl = this.partnerPortalUrl.replace('{languageCode}', this.userInformationService.language)
            .replace('{countryCode}', this.userInformationService.country.toLowerCase());
        this.partnerPortalControlUrlDE =
            this.partnerPortalControlUrlDE.replace('{serialnumber}', this.systemDetail?.structure.find(s => s.vitoguideId).serialNumber);
    }

    back() {
        this.userInformationService.shownEquipment = null;
        this.router.navigate(['../list']);
    }

    ngOnDestroy(): void {
        if (this.subscription != undefined) {
            this.subscription.unsubscribe();
        }
    }

    openDialog() {
        this.dialog.open(IncompleteInformationComponent);
    }

    sendCertificate() {
        this.dialog.open(CertificateComponent, {
            data: {
                equipmentNumber: this.systemDetail.equipment.equipmentNumber,
                emails: this.userInformationService.user.contacts.email
                //emails: this.readMails(),
            }
        });
    }

    readMails(): string[] {
        let mails: string[] = [];
        if (this.systemDetail.location.eMail && this.systemDetail.location.eMail.match(this.validateEmail)) {
            mails.push(this.systemDetail.location.eMail);
        }
        for (let entry of this.systemDetail.partner) {
            // No empty Mail
            if (entry.eMail != '') {
                //No duplicate Mail
                if (mails.length > 0) {
                    let alreadyExists: boolean = false;
                    for (let mailsEntry of mails) {
                        if (mailsEntry == entry.eMail) {
                            alreadyExists = true;
                        }
                    }
                    if (alreadyExists == false && entry.eMail.match(this.validateEmail)) {
                        mails.push(entry.eMail);
                    }
                } else {
                    if (entry.eMail.match(this.validateEmail)) {
                        mails.push(entry.eMail);
                    }
                }
            }
        }
        return mails;
    }

    openNotRegisteredDialog(serialNumber: string): void {
        const dialogRef = this.dialog.open(NotRegistratedPopupComponent, {
            width: '480px',
            data: {
                serialNumber: serialNumber,
                system: null
            }
        });
    }

    openNotFoundDialog(serialNumber: string): void {
        const dialogRef = this.dialog.open(NotFoundPopupComponent, {
            width: '480px',
            data: {
                serialNumber: serialNumber
            }
        });
    }

    getSEquiSerialNumber () {
        const structure = this.systemDetail.structure.filter( (s) => s.equipmentNumber === this.systemDetail.equipment.equipmentNumber);
        if (structure.length > 0) {
            return structure[0].serialNumber;
        }
        return '';
    }

}
